import * as React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import HeaderBar from "../components/header-bar"
import Container from "../components/container"
import Button from "../components/button"

const NotFoundPage = () => {
  return (
    <Layout>
      <Hero />
      <HeaderBar />
      <div className={"bg-white"} id={"about"}>
        <Container className={"px-6 py-12 md:gap-8 flex-wrap justify-center"}>
          <div>
            <h1 className={"text-4xl mb-6 uppercase"}>404</h1>
            <div className={"prose-lg max-w-4xl font-cursive"}>
              <h3>Page not found.</h3>
              <p>Sorry, we couldn’t find the page you’re looking for.</p>
              <Button theme={"light"} hoverTheme={"dark"} href={"/"}>
                Go back home
              </Button>
            </div>
          </div>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFoundPage
